<template>
  <div :class="$store.getters['layout/getInnerWidth'] >= 991 ? '' : 'text-left'">
    <template v-for="(btn, i) in item.actions">
      <span
          :key="'data-table-index' + i"
          @click.stop="onActionClick (btn, item)"
          class="icon-2x icon-custom-size"
          :class="isBusy === btn ? '' : 'pointer'"
      >
        <i v-if="isBusy !== btn" :class="getBtnIconClass(btn)"></i>
        <i v-else class="btn-edit-size fa fa-spinner fa-spin"></i>
      </span>
    </template>
  </div>
</template>
<script>
export default {
  data: () => ({
    isBusy: '',
  }),
  props: {
    //to do show spinner after confirm click
    isDeleting: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: this,
    },
  },
  beforeDestroy () {
    this.isBusy = '';
  },
  methods: {
    onActionClick (btn, item) {
      if(btn === 'delete') {
        if (this.isBusy === '') {
          this.$emit('on:data-table-btn:' + btn, item);
        }
      } else {
        this.$emit('on:data-table-btn:' + btn, item);
      }
    },
    getBtnIconClass(btn) {
      switch (btn) {
        case 'show': {
          return 'btn-close-size icofont icofont-eye-alt'
        }
        case 'delete': {
          return 'btn-close-size icofont icofont-close'
        }
        case 'edit': {
          return 'btn-edit-size fa fa-pencil'
        }
        case 'history': {
          break;
        }
      }
    },
  }
}
</script>
<style scoped>
.btn-edit-size {
  font-size: 18px;
}

.btn-close-size {
  font-size: 20px;
}

.icon-custom-size {
  padding: 0.175rem 0.45rem;
  color: #919194;
  opacity: 1;
}

.icon-custom-size:hover {
  color: #165873;
}
</style>
